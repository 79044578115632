<template>
  <v-footer app dark class="transparent" style="background-image: linear-gradient(rgba(20,28,75,0.73), #141C4B)">
    <v-row no-gutters class="text-center">
      <v-col align="start">
        <v-btn icon @click="$emit('toggle-drawer')">
          <app-icon icon="burger-menu"/>
        </v-btn>
      </v-col>
      <v-col>
        <v-btn icon
               @click="$router.currentRoute.path !== '/' ? $router.push('/') : ''">
          <app-icon icon="home"/>
        </v-btn>
      </v-col>
      <v-col align="end">
        <v-btn icon @click="$emit('toggle-options-drawer')">
          <app-icon icon="settings" size="18"/>
        </v-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
  export default {
    name: 'TheAppFooterMobile',
  }
</script>
