<template>
  <v-snackbar rounded bottom color="primary darken-2" v-model="active" :timeout="getSnackbar.timeout">
    <div @click.stop="toggleOff" class="d-flex pa-0 justify-center align-center">
      <div class="col-10 py-0">
        <span class="body-2">{{ getSnackbar.text }}</span>
      </div>
      <div class="col-2 pa-0">
        <v-btn dark icon class="d-inline">
          <app-icon icon="close"/>
        </v-btn>
      </div>
    </div>
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TheSnackbar',

  computed: {
    ...mapGetters('view', ['getSnackbar']),
    active: {
      set() {
        this.toggleOff()
      },
      get() {
        return this.getSnackbar.active
      }
    }
  },

  methods: {
    ...mapActions('view', ['setSnackbar']),
    toggleOff() {
      this.setSnackbar({ ...this.getSnackbar, active: false })
    }
  }
}
</script>
