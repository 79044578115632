<template>
  <div>
    <v-toolbar dense flat color="primary darken-4">
      <v-toolbar-title>
        <slot name="title"/>
      </v-toolbar-title>
      <v-spacer/>
      <v-btn icon @click="$emit('close')">
        <app-icon icon="close" color="white"/>
      </v-btn>
    </v-toolbar>
    <div :class="`pa-${padding} ${bgColor}`">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name:  'AppDialog',
  props: {
    bgColor: {
      type:    String,
      default: 'primary darken-1'
    },
    padding: {
      type: Number,
      default: 5,
    }
  }
}
</script>
