<template>
  <app-dialog @close="$emit('close')">
    <template #title>Help</template>
    <div class="pa-3">
      <app-help/>
    </div>
  </app-dialog>
</template>

<script>
export default {
  name: 'TheHelpDialog',

  components: {
    AppHelp:   () => import(/* webpackChunkName: "app-main" */ 'src/sections/AppHelp'),
    AppDialog: () => import(/* webpackChunkName: "app-main" */ 'framework/ui/components/AppDialog')
  }
}
</script>
