<template>
  <v-navigation-drawer app bottom height="260" v-model="drawer" class="primary darken-1">
    <v-layout column fill-height>
      <v-list class="transparent">
        <v-list-item class="font-weight-medium body-2 elevation-1"
                     v-for="page in appPages"
                     :key="page.name"
                     :to="page.path">
          {{ page.name }}
        </v-list-item>
      </v-list>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
  import appConfig     from 'app-config/app.json'
  import UsageCounters from 'reporting/UsageCounters'

  export default {
    name: 'TheAppDrawerMobile',

    data() {
      return {
        drawer: false,
        appPages:  [
          {
            'name':  'Home',
            'path': '/',
          }, ...appConfig.appRoutes,
        ],
      }
    },

    updated() {
      if(this.drawer) {
        ++UsageCounters.opened_drawer
      }
    },
  }
</script>
