<template>
  <app-dialog @close="$emit('close')">
    <template #title>Settings</template>
    <div class="pa-2 primary darken-2">
      <v-list class="transparent">
        <v-list-item v-for="([settingName, props]) in Object.entries(settings)"
                     :key="settingName"
                     class="elevation-1">
          <v-list-item-icon :class="`px-0 ${props.type === 'slider' ? 'pt-8' : ''}`" @click="getHelp(props.help)">
            <v-btn icon small>
              <app-icon icon="help"/>
            </v-btn>
          </v-list-item-icon>
          <v-list-item-content>
            {{ props.name }}
          </v-list-item-content>
          <v-spacer/>
          <v-list-item-content v-if="props.type === 'text'">
            <v-text-field single-line
                          max-width="10"
                          v-model="props.value"
                          type="number"/>
          </v-list-item-content>
          <v-list-item-content v-if="props.type === 'number'">
            <v-text-field single-line
                          max-width="10"
                          v-model="props.value"
                          type="number"/>
          </v-list-item-content>
          <v-list-item-action v-else-if="props.type==='select'">
            <v-select dense hide-details
                      v-model="props.value"
                      :items="props.items"
                      :value="props.value"/>
          </v-list-item-action>
          <v-list-item-action v-else-if="props.type==='boolean'">
            <v-switch v-model="settings[settingName].value" color="secondary"/>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-row dense class="text-center">
        <v-col>
          <v-btn block rounded
                 :small="$vuetify.breakpoint.mdAndDown"
                 @click="save" color="success darken-1">
            Save
          </v-btn>
        </v-col>
        <v-col>
          <v-btn block rounded
                 :small="$vuetify.breakpoint.mdAndDown"
                 color="error" @click="$emit('close')">
            Close
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-snackbar top v-model="help" color="primary darken-2" :timeout="5000">
      <div class="text-center">
        <span @click.stop="help=false" class="body-1">{{ helpText }}</span>
      </div>
    </v-snackbar>
  </app-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AppSettings                from 'app-config/settings.json'

export default {
  name: 'TheSettingsDialog',

  components: {
    AppDialog: () => import(/* webpackChunkName: "app-main" */ 'framework/ui/components/AppDialog')
  },

  // Values are taken from the settings getters and updated to the local component settings object to override the defaults
  beforeMount() {
    const values = {
      sustain: this.isSustain,
      micSensitivity: this.getMicSensitivity
    }
    Object.entries(values).forEach(([key, val]) => {
      this.settings[key].value = val
    })
  },

  data() {
    return {
      settings: AppSettings,
      help:     false,
      saved:    false,
      helpText: ''
    }
  },

  computed: {
    ...mapGetters('settings', [
      'getMelodicInterval', 'shouldResolveScales', 'shouldResolveChords', 'isSustain',
      'getMasterVolume', 'getMicSensitivity'
    ])
  },

  methods: {
    ...mapActions('settings', ['updateSettings', 'updateMasterVolume']),
    ...mapActions('view', ['setSnackbar']),

    async save() {
      await this.updateSettings(this.settings)
      this.setSnackbar({ text: 'Settings saved!', timeout: 2000, active: true })
    },

    getHelp(text) {
      this.helpText = text
      this.help     = true
    },

    volumeChange(vol) {
      this.settings.volume.value = vol
      this.updateMasterVolume(vol)
    }
  }
}
</script>
