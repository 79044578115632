<template>
  <v-dialog v-model="active" max-width="350">
    <app-dialog @close="toggleOff">
      <template #title>{{ getDialog.title }}</template>
      <div class="pa-5 secondary select-all overflow-auto text-break">
        <span v-html="getDialog.body"/>
      </div>
    </app-dialog>
  </v-dialog>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'TheMainDialog',

    components: {
      AppDialog: () => import(/* webpackChunkName: "app-main" */ 'framework/ui/components/AppDialog'),
    },

    computed: {
      ...mapGetters('view', ['getDialog']),
      active: {
        set() {
          this.toggleOff()
        },
        get() {
          return this.getDialog.active
        },
      },
    },

    methods: {
      ...mapActions('view', ['setDialog']),
      toggleOff() {
        this.setDialog({ ...this.getDialog, active: false })
      },
    },
  }
</script>
