<template>
  <v-navigation-drawer app dark clipped v-model="drawer"
                       width="180"
                       class="primary"
                       style="background-image: var(--theme-top);">
    <v-layout column fill-height>
      <v-list :nav="$vuetify.breakpoint.mdAndUp"
              :subheader="$vuetify.breakpoint.smAndDown">
        <v-subheader>
          Yo, {{ getUser ? getUser.username : 'Guest' }}!
        </v-subheader>
        <v-divider/>
        <v-list-item class="font-weight-medium text-sm-body-2 caption elevation-1"
                     v-for="page in appPages"
                     :key="page.name"
                     :to="page.path">
          {{ page.name }}
        </v-list-item>
      </v-list>
      <v-spacer/>
      <v-list dense
              class="drawer-lower-list font-weight-regular text-sm-body-2 caption">
        <v-list-item v-if="showInstrumentChangeButton"
                     @click="$emit('show-instrument-change-dialog')">
          Change Instrument
        </v-list-item>
        <v-list-item @click="$emit('show-settings-dialog')">
          Settings
        </v-list-item>
        <v-list-item to="/profile">
          View Profile
        </v-list-item>
        <v-divider/>
        <v-list-item dense
                     class="font-weight-light text-sm-body-2 caption text-capitalize"
                     v-for="path in bottomPaths"
                     :key="path.name"
                     :to="path.path">
          {{ path.name }}
        </v-list-item>
      </v-list>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import appConfig      from 'app-config/app.json'

export default {
  name: 'TheAppDrawer',

  props: {
    showInstrumentChangeButton: Boolean
  },

  data() {
    return {
      drawer:      this.$vuetify.breakpoint.lgAndUp,
      appPages:       [
        {
          'name':  'Home',
          'path': '/'
        }, ...appConfig.appRoutes
      ],
      bottomPaths: appConfig.defaultRoutes
    }
  },

  computed: {
    ...mapGetters('user', ['getUser'])
  }
}
</script>


