<template>
  <v-app-bar app dark clipped-left
             elevate-on-scroll rounded
             :dense="$vuetify.breakpoint.smAndDown"
             class="app-bar">
    <v-btn icon text
           v-if="$vuetify.breakpoint.xsOnly && isIOSDevice"
           @click="$router.currentRoute.path !== '/' ? $router.push('/') : ''">
      <app-icon icon="logo"/>
    </v-btn>
    <v-btn icon v-else @click.stop="$emit('toggle-drawer')">
      <app-icon icon="burger-menu"/>
    </v-btn>
    <v-spacer/>
    <v-menu offset-y auto>
      <template #activator="{ on }">
        <v-btn icon v-on="on">
          <app-icon icon="person"/>
        </v-btn>
      </template>
      <v-list dense class="transparent" style="background-color: var(--theme-top)!important;">
        <v-list-item :to="getIsLoggedIn ? 'profile' : 'login'">
          <v-list-item-action>
            <app-icon icon="person"/>
          </v-list-item-action>
          <v-list-item-title>
            {{ getIsLoggedIn ? 'Profile' : 'Login' }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="getIsLoggedIn" to="#"
                     @click="$emit('open-logout-dialog')">
          <v-list-item-action>
            <app-icon icon="account-box"/>
          </v-list-item-action>
          <v-list-item-title>
            Logout
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-for="(item, index) in items"
                     :key="index"
                     @click="item.action()">
          <v-list-item-action>
            <app-icon :icon="item.icon" color="white"/>
          </v-list-item-action>
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapGetters }    from 'vuex'
import { playstoreUrl }  from 'app-config/app.json'
import appInstanceConfig from 'framework/environment/platform'

export default {
  name: 'TheAppBar',
  data() {
    return {
      items: [
        {
          icon:   'help',
          name:   'Help',
          action: () => { this.$emit('open-help-dialog') }
        },
        {
          icon:   'thumbs-up',
          name:   'Rate',
          action: () => {
            window.open(
                playstoreUrl,
                '_blank'
            )
          }
        }
      ]
    }
  },

  computed: {
    ...mapGetters('user', ['getIsLoggedIn']),
    isIOSDevice: function() {
      return appInstanceConfig.isIOS
    }
  }
}
</script>

<style lang="scss">
.app-bar {
  background-image: linear-gradient(var(--theme-top), var(--theme-top-darkened));
}
</style>
