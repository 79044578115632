<template>
  <v-navigation-drawer app bottom height="280" v-model="drawer" class="primary darken-1">
    <v-layout column fill-height>
      <v-list dense class="transparent">
        <v-list-item v-if="showInstrumentChangeButton"
                     class="font-weight-medium caption"
                     @click="action('show-instrument-change-dialog')">
          Change Instrument
        </v-list-item>
        <v-list-item @click="action('show-settings-dialog')"
                     class="font-weight-medium caption">
          Settings
        </v-list-item>
        <v-list-item to="/profile" class="font-weight-medium caption">
          View Profile
        </v-list-item>
      </v-list>
      <v-divider/>
      <v-spacer/>
      <v-list dense class="transparent">
        <v-list-item dense
                     class="font-weight-regular overline text-capitalize"
                     v-for="path in defaultRoutes"
                     :key="path.name"
                     :to="path.path">
          {{ path.name }}
        </v-list-item>
      </v-list>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
  import { mapGetters } from 'vuex'
  import appConfig from 'app-config/app.json'
  import UsageCounters  from 'reporting/UsageCounters'

  export default {
    name: 'TheOptionsDrawer',

    data() {
      return {
        drawer: false,
        defaultRoutes: appConfig.defaultRoutes,
      }
    },

    props: {
      showInstrumentChangeButton: { type: Boolean },
    },

    computed: {
      ...mapGetters('user', ['getUser']),
    },

    methods: {
      action(actionName) {
        this.drawer = false
        this.$emit(actionName)
      },
    },

    updated() {
      if(this.drawer) {
        ++UsageCounters.opened_options_drawer
      }
    },
  }
</script>


