<template>
  <div>
    <span class="title font-weight-black">Welcome to Vocal Coach!</span>
      <br><br>
      This app contains a lot of different tools for singers and singing-students.
      <br>
      Our features:
      <br><br>
      <span class="title font-weight-black">Pitch Practice:</span>
      <br>
      Pitch Practice helps you get better at hitting notes out of context.<br>
      After choosing a range and the rest of the settings, it will simply play random notes within the range and give you time to try to sing the same note with your voice.
      <br>
      <br>
      <span class="title font-weight-black">Exercises:</span>
      <br>
      Exercises are vocal training exercises played for you to sing along with.
      <br>
      <ol>
        <li>Choose the range.</li>
        <li>Choose the exercise out of the list of exercises or create your own easily.</li>
        <li>Setup the other settings and start.</li>
      </ol>
      <br>
      You can check your performance after stopping or finishing an exercise.
      <br><br>
      <span class="title font-weight-black">Pitch Detection:</span>
      <br>
      Here you can simply see the notes you are singing with your voice, and how accurate they are on both a slider with the note name & a piano.
      <br><br>
      <span class="title font-weight-black">Metronome:</span>
      <br>
      A simple and configurable metronome to be used for any task requiring timing.
      <br><br>
      * You can also get a link for every exercise with your specific configuration and share it easily, or use it to access the exercise from anywhere.
    <br><br>
    BTW, if you have any tips/suggestions or notice any bugs you are
    more than welcome to email us at contact-us@musitelligence.com and we will add them :)
  </div>
</template>

<script>
  export default {
    name: 'AppHelp',
  }
</script>
